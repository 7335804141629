#wiki-page {
  color: white;
  width: min(95vw, 1800px);
  max-width: 100%;
}

#wiki-page h2 {
  display: flex;
  align-items: center;
  gap: 4px;
}

#wiki-page ul {
  padding: 0;
}

#wiki-page li {
  list-style: none;
}

#wiki-page li[role="tab"] {
  text-transform: uppercase;
}

#wiki-page li[role="tab"] > img.pokemon-portrait {
  display: block;
  margin: -6px -12px;
}

.wiki-modal {
  overflow: visible;
}

#wiki-page .wiki-types li[role="tab"] {
  padding: 4px;
  line-height: 40px;
  min-width: 48px;
  text-align: center;
}

#wiki-page .synergy-overlaps {
  display: grid;
  max-width: 300px;
  grid-template-columns: repeat(auto-fit, 80px);
  gap: 0.5em;
  margin: 1em;
}

.synergy-overlaps li {
  display: flex;
  align-items: center;
  background-color: var(--color-bg-secondary);
  border-radius: 8px;
  background-color: var(--color-bg-secondary);
  border: var(--border-extra-thin);
  cursor: var(--cursor-hover);
}

.synergy-overlaps li.active {
  background-color: var(--color-bg-accent);
}

.synergy-overlaps li > img:nth-child(2) {
  margin-left: -25px;
  z-index: 2;
}

#wiki-page > .react-tabs > .react-tabs__tab-panel {
  height: 75vh;
  padding: 0.5em 0 40px 0;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

#wiki-page .pokemon-name {
  text-transform: capitalize;
}

#wiki-page .pokemon-portrait {
  margin: 2px;
}

#wiki-page .pokemon-portrait > img {
  display: block;
  border-radius: 2px;
}

#wiki-page .wiki-pokemons .pokemon-typeahead {
  float: right;
  font-size: 80%;
  padding: 0px 16px 0px 8px;
}

#wiki-page p.description {
  line-height: 1.2em;
}

#wiki-page .wiki-faq summary {
  color: var(--color-fg-green);
}

#wiki-page .wiki-faq details {
  margin-bottom: 0.5em;
}

#wiki-page .wiki-status,
#wiki-page .wiki-stat {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  gap: 0.5em;
}

#wiki-page .wiki-status li {
  list-style: none;
  display: grid;
  grid-template: auto 1fr / 30% 1fr;
  padding: 0.5em;
  min-height: calc(122px + 1em);
  column-gap: 0.5em;
}

#wiki-page .wiki-status h2 {
  margin: 0;
  font-size: 2rem;
}

#wiki-page .wiki-stat h2 {
  margin: 0;
}

#wiki-page .wiki-stat li {
  list-style: none;
  display: grid;
  grid-template: auto 1fr / 80px 1fr;
}

#wiki-page .wiki-status li > img,
#wiki-page .wiki-stat li > img {
  grid-row: 1 / 3;
  width: 100%;
}

#wiki-page .wiki-status li > img {
  border-radius: 8px;
  border: 2px solid #000000;
}

#wiki-page .wiki-stat li > img {
  width: 64px;
  height: 64px;
  margin-right: 10px;
}

#wiki-page .wiki-status li .status-label {
  white-space: initial;
}

#wiki-page .wiki-tutorials ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0;
}

#wiki-page .wiki-tutorials h3 {
  margin: 0;
}

#wiki-page .wiki-tutorials iframe {
  width: 560px;
  height: 315px;
  max-width: 100%;
}

#wiki-page .wiki-types .pokemon-portrait {
  display: inline-block;
}

#wiki-pokemons-all section {
  border-top: 4px solid currentColor;
}

#wiki-types-all section {
  border-top: 4px solid #40404080;
}

#wiki-pokemons-all section ul,
#wiki-types-all section ul {
  display: flex;
  flex-wrap: wrap;
}

#wiki-weather > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  gap: 0.5em;
}

#wiki-weather > ul > .my-box {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

#wiki-ability h2 {
  margin: 0;
  font-size: 2rem;
}

#wiki-ability > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  gap: 0.5em;
  margin: 0;
}

#wiki-ability > ul > .my-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#wiki-regions > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  gap: 0.5em;
}

#wiki-regions > ul > .my-box {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: relative;
}

#wiki-regions > ul > .my-box > img {
  border: 1px solid #000000;
  border-radius: 4px;
  box-shadow: 2px 2px 0 #00000060;
  height: 250px;
  object-fit: cover;
}

#wiki-regions .my-box h2 {
  margin: 0;
  font-size: 1.5rem;
}

#wiki-regions .wiki-regional-mons {
  position: absolute;
  bottom: 1em;
  left: 1em;
  right: 1em;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

#wiki-weather .my-box header {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

#wiki-weather .my-box h2 {
  flex: 1;
  margin: 0;
  font-size: 2rem;
}

#wiki-weather .my-box header .weather-icon {
  aspect-ratio: 1 / 1;
  height: 40px;
}

#wiki-weather .my-box p {
  margin: 0;
  white-space: pre-line;
}

#wiki-weather .my-box ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0;
  margin: 0;
}

#wiki-ability .my-box ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0;
}

#wiki-ability .my-box p {
  font-size: 0.8em;
}

#wiki-ability img.item {
  width: 45px;
  height: 45px;
}

#wiki-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  justify-content: space-evenly;
}

#wiki-items article {
  padding: 1em;
}

#wiki-items ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  padding: 0;
  margin: 0;
}

#wiki-items .craftable table {
  margin: 1em;
}

#wiki-items .berries {
  grid-column: 1 / 3;
}

#wiki-items article:nth-child(n + 3) {
  grid-column: 1 / 3;
}

#wiki-items img.item {
  width: 45px;
  height: 45px;
  cursor: var(--cursor-hover);
  margin: 8px;
}

#wiki-items img.tree {
  width: 50px;
  margin: -8px 8px 0 8px;
}

#wiki-items th,
#wiki-items td:first-child {
  background-color: var(--color-bg-secondary);
}

#wiki-items tr:nth-child(odd),
#wiki-items td:nth-child(n + 2) {
  background-color: var(--color-bg-primary);
}

#wiki-data {
  padding: 0 0.5em;
}

#wiki-data table {
  text-align: center;
  table-layout: fixed;
  background-color: var(--color-bg-secondary);
}

#wiki-data thead {
  border-bottom: 2px solid currentColor;
}

#wiki-data th,
#wiki-data td {
  padding: 0 0.5em;
}

#wiki-data-tiers-by-level th,
#wiki-data-booster-rarity-probability th {
  width: 10ch;
}

#wiki-page input[type="search"] {
  display: block;
  margin: 0.5em 0 0.5em auto;
  width: auto;
}

@media (width <= 960px) {
  #wiki-items {
    display: block;
  }

  #wiki-items img.item {
    width: 24px;
    height: 24px;
    margin: 4px;
  }

  #wiki-page .wiki-types li[role="tab"] {
    padding: 4px;
  }
}
